import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "components/container";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query Contact {
      contentfulCompany {
        title
        name
        email
        website
        telephone
        street
        zip
        city
        vatId
      }
    }
  `);
  return (
    <footer className="w-full bg-gray-900 text-white py-8">
      <Container>
        <div className="sm:hidden">
          <div>{data.contentfulCompany.title}</div>
          <div>{data.contentfulCompany.email}</div>
          <div>{data.contentfulCompany.vatId}</div>
        </div>
        <div className="hidden sm:flex justify-around">
          <div>{data.contentfulCompany.email}</div>
          <div>{data.contentfulCompany.title}</div>
          <div>{data.contentfulCompany.vatId}</div>
        </div>
      </Container>
    </footer>
  );
};
export default Footer;

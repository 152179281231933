import React from "react";
import { Link } from "gatsby";
import { Menu, Transition } from "@headlessui/react";
import Container from "components/container";
import Logo from "components/logo";

export const Header = () => (
  <Menu>
    {({ open }) => (
      <div className="fixed w-full bg-gray-900 z-50">
        <Container>
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Logo />
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              {!open && (
                <Menu.Button className="p-2 rounded-md inline-flex items-center justify-center text-white hover:text-gray-300 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </Menu.Button>
              )}
            </div>
            <nav className="hidden md:flex space-x-10">
              <Link
                to="/"
                className="text-base font-medium text-white hover:text-gray-300"
              >
                Etusivu
              </Link>
              <Link
                to="/yhteystiedot/"
                className="text-base font-medium text-white hover:text-gray-300"
              >
                Yhteystiedot
              </Link>
            </nav>
          </div>
        </Container>
        <Transition
          show={open}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-gray-800 divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Logo />
                  </div>
                  <div className="-mr-2">
                    <Menu.Button class="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-300 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </Menu.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <Menu.Items static className="grid gap-y-8">
                    <Menu.Item>
                      <Link
                        to="/"
                        activeClassName="bg-black text-white"
                        className={`-m-3 p-3 flex items-center rounded-md text-gray-300 hover:bg-gray-700 hover:text-white`}
                      >
                        <svg
                          className="flex-shrink-0 h-6 w-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                          />
                        </svg>
                        <span className="ml-3 text-base font-medium">
                          Etusivu
                        </span>{" "}
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        to="/yhteystiedot/"
                        activeClassName="bg-black text-white"
                        className={`-m-3 p-3 flex items-center rounded-md text-gray-300 hover:bg-gray-700 hover:text-white`}
                      >
                        <svg
                          className="flex-shrink-0 h-6 w-6 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                          />
                        </svg>
                        <span className="ml-3 text-base font-medium">
                          Yhteystiedot
                        </span>{" "}
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    )}
  </Menu>
);
export default Header;

import React from "react";
import Container from "./container";
import Header from "./header";
import Footer from "components/footer";

export const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <Container>
        <div
          className="pt-24 relative"
          style={{ minHeight: "calc(100vh - 6rem)" }}
        >
          {children}
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;

import React from "react";
import logoImage from "assets/img/hakio-logo-transparent-white.png";
import Link from "gatsby-link";
export const Logo = () => {
  return (
    <Link to="/">
      <span className="sr-only">Hakio Consulting Oy</span>
      <img alt="Hakio Consulting Oy logo" src={logoImage} className="-ml-1" />
    </Link>
  );
};
export default Logo;
